@keyframes fadeInFromNone{
  0% {
    opacity: 0;
  }

  1% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes rotate180{
  0% {
    transform: rotate(0);
  }
  1% {
    transform: rotate(2deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
