.wizard-progress {
  &.container {
    padding: 10px;
  }

  .cls-1 {
    fill: $text-color;
  }

  .step-active {
    .step-name {
      color: $color2;
    }
    .cls-1 {
      fill: $color2;
    }
  }

  .step-name {
    color: $text-color;
    display: none;
    padding-top: 5px;
  }

  a {
    font-size: 14px;
    position: relative;
    width: 60px;
  }
}

@media screen and (min-width: 768px) {
  .wizard-progress {
    .step-name {
      display: block;
    }

    .step {
      width: 150px;
    }

    li {
      width: 100%;
      position: relative;

      &:last-of-type {
        &::before {
          display: none;
        }
      }

      &::before {
        background-color: $text-color;
        content: '';
        height: 1px;
        left: 93px;
        position: absolute;
        top: 15px;
        width: calc(100% - 35px);
      }
    }
  }
}

@media screen and (min-width: 980px) {
  .wizard-progress {
    li {
      &::before {
        left: 100px;
        top: 21px;
        width: calc(100% - 48px);
      }
    }
  }
}
