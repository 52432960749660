mwl-gauge {
  display: block;
  height: 130px;
  width: 150px;

  &.sm {
    height: 50px;
    width: 80px;
  }

  > .gauge {
    > .dial {
      fill: rgba(0,0,0,0);
      stroke: $shadow;
      stroke-width: 3;
    }

    .value {
      fill: rgba(0,0,0,0);
      stroke: $color3;
      stroke-width: 5;

      &.light-blue {
        stroke: $color3;
      }

      &.blue {
        stroke: $color2;
      }

      &.orange {
        stroke: $color5;
      }

      &.red {
        stroke: $red;
      }
    }

    .value-text {
      fill: $color3;
      font-family: sans-serif;
      font-size: 1em;
      font-weight: bold;
      stroke-width: 5;

      &.blue {
        fill: $color2;
      }

      &.orange {
        fill: $color5;
      }

      &.red {
        fill: $red;
      }
    }
  }
}
