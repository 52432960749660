h1 {
  color: $black;
  font-size: 24px;
  font-weight: normal;
  line-height: 35px;
  margin: 0;
}

h2 {
  color: $black;
  font-size: 22px;
  font-weight: normal;
  line-height: 30px;
  margin: 0;
}

h3 {
  color: $black;
  font-size: 20px;
  font-weight: normal;
  line-height: 30px;
  margin: 0;
}

h4 {
  color: $black;
  font-size: 18px;
  font-weight: normal;
  line-height: 30px;
  margin: 0;
}

h5 {
  color: $black;
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
  margin: 0;
}

h6 {
  color: $black;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  margin: 0;
}

@media screen and (min-width: 980px) {
  h1 {
    font-size: 30px;
    line-height: 40px;
  }

  h2 {
    font-size: 27px;
    line-height: 30px;
  }

  h3 {
    font-size: 24px;
    line-height: 30px;
  }

  h4 {
    font-size: 22px;
    line-height: 30px;
  }

  h5 {
    font-size: 20px;
    line-height: 30px;
  }

  h6 {
    font-size: 18px;
    line-height: 20px;
  }
}
