@import url('https://fonts.googleapis.com/css?family=Asap:400,700|Roboto&display=swap');

body {
  color: $text-color;
  font-family: $ff1;
  font-size: 15px;
  line-height: $lh;
  margin: 0;
  padding: 0;
}

footer {
  flex-shrink: 0;
}

html,
body,
app-root {
  display: block;
  height: 100%;
}

label {
  font-weight: bold;
}

.divider-vertical {
  background-color: $text-color;
  height: 100%;
  width: 1px;

  &.divider-30 {
    height: 30px;
  }
}

a {
  color: $color3;
}

.site-content {
  align-self: stretch;
  flex: 1 0 auto;
}

img {
  max-width: 100%;
}

.font-family-1 {
  font-family: $ff1;
}

.font-family-2 {
  font-family: $ff2;
}

* {
  box-sizing: border-box;
}

.float-left {
  float: left;
}

.block {
  display: block;
  margin: 0 auto;
}

.inline-block {
  display: inline-block;
  vertical-align: center;
}

.text-underline {
  text-decoration: underline;
}

.text-dashed {
  text-decoration: dashed underline;
}

a {
  &.text-underline {
    text-decoration: underline;
  }

  &.text-dashed {
    text-decoration: dashed underline;
  }
}

p {
  margin: 0;
}

.bdt-color2 {
  border-top: 3px solid $color2;
}

.no-margin {
  margin: 0;
}

.bdrs0 {
  @include border-radius(0);
}

.height100 {
  height: 100%;
}

.height25px {
  height: 25px;
}

.height30px {
  height: 30px;
}

.height100px {
  height: 100px;
}

.height150px {
  height: 150px;
}

.height200px {
  height: 200px;
}

.height400px {
  height: 400px;
}

.height-36 {
  height: 36px;
}

.height-40 {
  height: 40px;
}

.section {
  padding: 20px 0;
}

.img {
  max-width: 100%;
}

.flex {
  align-items: center;
  align-self: center;
  display: flex;
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-top {
  align-items: flex-start;
  align-self: flex-start;
}

.flex-start {
  align-items: flex-start;
  justify-content: flex-start;
}

.flex-items-start {
  align-items: flex-start;
  align-self: flex-start;
}


.flex-stretch {
  align-items: stretch;
}

.flex-around {
  justify-content: space-around;
}

.flex-between {
  justify-content: space-between;
}

.flex-center {
  justify-content: center;
}

.flex-end {
  justify-content: flex-end;
}

.bd-color1-sm {
  border: 1px solid $color1;
}

.bd-color2-sm {
  border: 1px solid $color2;
}

.bd-color3-sm {
  border: 1px solid $color3;
}

.mb0 {
  margin-bottom: 0;
}

.shadow {
  box-shadow: 0 0 .3rem rgba(0, 0, 0, 0.2) !important;
}

.width-1 {
  width: 100%;
}

.max-w-1 {
  max-width: 100%;
}

.max-w-12 {
  max-width: percentage(1 / 2);
}

.max-w-13 {
  max-width: percentage(1 / 3);
}

.width-12 {
  width: percentage(1 / 2);

  &.width-1-mobile {
    @extend .width-1;
  }
}

.width-13 {
  @extend .width-1;
}

.width-23 {
  @extend .width-1;
}

.width-14 {
  @extend .width-1;
}

.width-15 {
  @extend .width-1;
}

.width-16 {
  @extend .width-1;
}

a {
  color: $color3;
  text-decoration: none;

  &:hover {
    color: $color1;
  }
}

ul {
  list-style: none;
  margin: 0;
}

.upper {
  line-height: 30px;
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.color-white {
  color: $white;

  .cls-1 {
    fill: $white;
  }
}

.color1 {
  color: $color1;
  .cls-1 {
    fill: $color1;
  }
}

.color-dark-blue,
.color2 {
  color: $color2;
  .cls-1 {
    fill: $color2;
  }
}

.color-blue,
.color3 {
  color: $color3;
  .cls-1 {
    fill: $color3;
  }
}

.color4 {
  color: $color4;

  .cls-1 {
    fill: $color4;
  }
}

.color5 {
  color: $color5;

  .cls-1 {
    fill: $color5;
  }
}

.color6 {
  color: $color6;

  .cls-1 {
    fill: $color6;
  }
}

.color7 {
  color: $color7;

  .cls-1 {
    fill: $color7;
  }
}

.color8 {
  color: $color8;

  .cls-1 {
    fill: $color8;
  }
}

.color9 {
  color: $text-color;

  .cls-1  {
    fill: $text-color;
  }
}

.red {
  color: $red;

  .cls-1 {
    fill: $red;
  }
}

.bg-color-product {
  background-color: $color-product;
}

.bg-color1 {
  background-color: $color1;
  color: $white;
}

.bg-color2 {
  background-color: $color2;
  color: $white;
}

.bg-color3 {
  background-color: $color3;
  color: $white;
}

.bg-color4 {
  background-color: $color4;
  color: $white;
}

.bg-color8 {
  background-color: $color8;
}

.bd-bottom {
  border-bottom: 1px solid $text-color;
}

.bg-red {
  background-color: $red;
  color: $white;
}

.bg-grey {
  background-color: $color-grey;
}

.bg-text {
  background-color: $text-color;
}

.bg-grey-darker {
  background-color: $shadow2;
}

.color-shadow {
  color: $shadow;
}

.c-blue {
  color: $color3;
}

.fsmaller {
  font-size: smaller;
}

.fsmall {
  font-size: small;
}

.flarger {
  font-size: larger;
}

.flarge {
  font-size: 35px;
  line-height: 40px;
}

.flip-180 {
  transform: rotateY(180deg);
}

.rounded {
  @include rounded(20px);
}

.rounded-light {
  @include rounded(5px);
}

.bg-gradient-blue {
  @include gradient2(to right, $color-gradient-blue2, $color-gradient-blue 55%, $color-gradient-blue);
  color: $white;
}

.bg-gradient-blue-reverse {
  @include gradient2(to right, $color-gradient-blue, $color-gradient-blue2 55%, $color-gradient-blue2);
  color: $white;

  a {
    color: $white;
  }
}

.hidden {
  display: none;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.elem-flag {
  background: url("#{$themedomain}/svg/flag.svg") no-repeat;
  background-size: contain;
  color: $white;
  font-size: 13px;
  left: calc(50% - 40px);
  line-height: 14px;
  padding: 7px 20px;
  position: absolute;
  top: -8px;
  height: 28px;
}

.elem-before {
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
  }
}

.elem-before-15 {
  &::before {
    height: 8px;
    left: 0;
    top: 0;
    width: 100%;
  }
}

.elem-before-green {
  @extend .elem-before;
  @extend .elem-before-15;

  &:before {
    @include gradient2(to right, $color7, $color4 55%, $color4);
  }
}

.elem-before-popular {
  @extend .elem-before-green;
}

.elem-before-blue {
  @extend .elem-before;
  @extend .elem-before-15;

  &:before {
    @include gradient2(to right, $color-gradient-blue2, $color-gradient-blue 55%, $color-gradient-blue);
  }
}

.elem-before-blue-dark {
  @extend .elem-before;
  @extend .elem-before-15;

  &:before {
    background-color: $color2;
  }
}

.elem-before-red {
  @extend .elem-before;
  @extend .elem-before-15;

  &:before {
    background-color: $color5;
  }
}

.elem-before-yellow {
  @extend .elem-before;
  @extend .elem-before-15;

  &:before {
    background-color: $color6;
  }
}

.fc-orange {
  color: $color5;
}

.prepend-right {
  right: 15px;
  top: calc(50% - 10px);
}

.dotted {
  border-bottom: 1px dotted;
}

.link-black {
  color: $text-color;

  &:hover {
    color: $color3;
    cursor: pointer;
  }

  &.active {

  }
}

.link-orange {
  color: $color5;
}

.ov-hidden {
  overflow: hidden;
}

.nobd {
  border: 0;
}

.bd-grey {
  border: 1px solid $color-grey;
}

.lh-m {
  line-height: 25px;
}

.lh-l {
  line-height: 30px;
}

.flex-tablet-row {
  flex-direction: column;
}

.width-60 {
  width: 60px;
}

.width-100 {
  width: 100px;
}

.width-130 {
  width: 130px;
}

.width-150 {
  width: 150px;
}

.width-170 {
  width: 170px;
}

.width-200 {
  width: 200px;
}

.width-250 {
  width: 250px;
}

.width-400 {
  width: 400px;
}

code {
  @include border-radius(10px);
  background-color: $color1;
  color: $white;
}

@media screen and (max-width: 767px) {
  .hidden-mobile {
    display: none;
  }

  .width-12-mobile {
    width: percentage(1 / 2);
  }

  .mb-xl-mobile {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 768px) {
  .hidden-tablet {
    display: none;
  }

  .flex-tablet-row {
    flex-direction: row;
  }

  .container-220 {
    max-width: 220px;
  }

  .container-300 {
    max-width: 300px;
  }

  .container-400 {
    max-width: 400px;
  }

  .container-600 {
    max-width: 600px;
  }

  .container-768 {
    max-width: 768px;
  }

  .width-12 {
    &.width-1-mobile {
      width: percentage(1 / 2);
    }
  }

  .width-13 {
    width: percentage(1 / 3);
  }

  .width-23 {
    width: percentage(2 / 3);
  }

  .width-14 {
    width: percentage(1 / 4);
  }

  .width-15 {
    width: percentage(1 / 5);
  }

  .width-16 {
    width: percentage(1 / 6);
  }

  .flex-order-tablet-1 {
    order: 1;
  }

  .flex-order-tablet-2 {
    order: 2;
  }
}

@media screen and (min-width: 980px) {
  .width-13-tablet {
    width: percentage(1 / 3);
  }
}

@media screen and (min-width: 1280px) {
  .container-1280 {
    max-width: 1280px;
  }
  .container {
    padding: 0;
  }
}
