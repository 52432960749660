@keyframes loader {
  0%, 20%, 80%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}


@for $i from 1 through 9 {
  .loader div:before:nth-child(#{$i}) {
    animation-delay: #{0.1 - (0.1 * $i)}s;
  }
}

.loader {
  @include on-circle($item-count: 9, $circle-size: 30px, $item-size: 3px);

  div {
    display: block;
    max-width: 100%;

    &:before {
      @include border-radius(50%);
      animation: loader 1.2s linear infinite;
      content: '';
      background: $color2;
      height: 100%;
      position: absolute;
      width: 100%;
    }
  }
}


.loader-50 {
  @include on-circle($item-count: 9, $circle-size: 50px, $item-size: 5px);
}
