:root {
  --select-bg-path: url("/assets/svg/FAQ-downMDK.svg");
  --bg-background: url("/assets/svg/background.svg") no-repeat center;
  --search-check: url("/assets/svg/search-check.svg") no-repeat center;
}

$nav-a-bg: #eee;

$ff1: 'Asap', sans-serif;
$ff2: 'Roboto', sans-serif;
$themedomain: '/assets/';
$lh: 20px;

$color1: #001b41;
$color2: #021482;
$color3: #0e81d3;
$color10: #1542A6;
$color-gradient-blue: #06c1e4;
$color-gradient-blue2: #388de4;
$color-table: rgba(215, 247, 255, .4);

$color-gradient-blue-dark: #0e9be4;
$color-gradient-blue-dark2: #1f3ee4;

$color-gradient-pink: #59249b;
$color-gradient-pink2: #7617eb;

$color7: #1f8435;
$color4: #2ed557;
$color-product: #f5f7f8;

$color-product1: rgba(254, 112, 51, .08);
$color5: #fe7033;
$color6: #ffb400;

$white: #fff;
$black: #333;
$color8: rgba(215, 247, 255, .4);
$color9: rgba(240, 250, 255, .5);
$text-color: #606060;
$color-grey: #f5f7f8;
$shadow: #afb1b1;
$shadow2: #F2F2F2;

$red: #F92636;
