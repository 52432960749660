.accordion-toggle {
  > .btn {
    text-align: left;
    width: 100%;
  }
}

.panel {
  background-color: $white;
  margin-bottom: 10px;

  &.p0 {
    .panel-body {
      padding: 0;
    }

    .panel-heading {
      margin-bottom: 10px;
      padding: 0;
    }

    .accordion-heading {
      padding: 0;
    }
  }
}

.panel-heading {
  background-color: $color-grey;
  border: 0;
}

.accordion-heading {
  line-height: 30px;

  &.btn {
    border: 0;
    line-height: 30px;
  }
}

.product-list {
  &.vps {
    .panel-heading {
      .btn {
        padding-left: 40px;

        &::before {
          left: 15px;
          position: absolute;
          top: 15px;
        }
      }
    }
  }
}

.vps {
  select {
    padding-left: 100px;
  }

  .absolute.tar {
    right: 160px;
  }

  .arrow-down {
    position: relative;

    &::after {
      border-color: $text-color transparent transparent;
      border-style: solid;
      border-width: 10px 5px 0;
      content: '';
      display: inline-block;
      position: absolute;
      right: 13px;
    }
  }

  .btn-link {
    &:hover {
      text-decoration: none;
    }
  }
}

.slider {
  -webkit-appearance: none;
  -webkit-transition: .2s;
  appearance: none;
  height: 25px;
  background: $color-grey;
  outline: none;
  opacity: .7;
  width: 100%;
  transition: opacity .2s;

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background: $color2;
    border-radius: 50px;
    cursor: pointer;
    height: 25px;
    width: 25px;
  }

  &::-moz-range-thumb {
    background: $color2;
    border-radius: 50px;
    cursor: pointer;
    height: 25px;
    width: 25px;
  }
}

.sliderticks {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;

  p {
    background: $color-grey;
    display: flex;
    font-size: 12px;
    height: 10px;
    justify-content: center;
    line-height: 40px;
    margin: 0 0 20px;
    position: relative;
    text-align: center;
    width: 1px;
  }
}
