.navbar-nav {
  > .active-parent {
    > ul {
      display: block;
    }
  }

  .nav-link {
    padding: 5px;

    &:hover {
      background-color: $color10;
      color: $white;
    }


  }

  .active {
    position: relative;

    &:before {
      border-left: 5px solid $color3;
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  ul {
    padding-left: 25px;

    .nav-link {
      padding-left: 10px;
    }
  }
}

.main-menu {
  bottom: 0;
  display: none;
  flex-shrink: 0;
  max-height: 100vh;
  overflow-y: auto;
  position: fixed;
  width: 250px;
  top: 0;
  left: 0;

  &.active {
    -webkit-animation-duration: 0.5s;
    -webkit-animation-name: fadeInFromNone;
    -webkit-animation-fill-mode: forwards;
    display: block;
    height: 100%;
    overflow-y: scroll;
    z-index: 10;
    width: 100%;
  }

  hr {
    background-color: $white;
  }

  a {
    color: $white;
  }

  .cls-1 {
    fill: $white;
    stroke: none;
  }

  .active {
    background-color: $color10;
    color: $white;
  }

  ul {
    width: 100%;

    a {
      padding-left: 10px;
    }

    ul {
      /* display: none; */
    }

    li {
      display: block;
      width: 100%;

      &.opened,
      &:hover {
        ul:not(.disable-hover) {
          display: block;
          -webkit-animation-duration: 0.5s;
          -webkit-animation-name: fadeInFromNone;
          -webkit-animation-fill-mode: forwards;
        }
      }
    }
  }
}

.secondary-menu {
  font-size: 0;

  &.us {
    .logo {
      display: none;
    }
  }

  .languages {
    position: relative;

    &:hover {
      .language-switcher {
        display: block;
      }
    }

    .language-switcher {
      background-color: $white;
      border: 2px solid $shadow;
      display: none;
      padding: 10px;
      position: absolute;
      right: 0;
      left:auto;
      text-align: center;
      top: 0;
      width: 150px;
    }

    li:not(:last-of-type) {
      margin-bottom: 10px;
    }

    a {
      color: $text-color;
      font-weight: bold;

      &:hover {
        color: $color2;
      }

      &.active {
        color: $color3;
      }
    }
  }

  .cls-1 {
    fill: $white;
  }

  a {
    &:not(.dropdown-item) {
      color: $white;
      &:hover {
        color: $white;
        cursor: pointer;
      }
    }
  }
}

.menu-toggle {
  &.active {
    .bar1 {
      transform: rotate(-45deg) translate(-7px, 4px);
    }

    .bar2 {
      opacity: 0;
    }

    .bar3 {
      transform: rotate(45deg) translate(-9px, -7px);
    }
  }
  .bar {
    background-color: $white;
    height: 3px;
    margin: 6px 0;
    transition: .4s;
    width: 30px;
  }
}

.main-content {
  padding: 0;
}

@media screen and (min-width: 768px) {
  .main-content {
    padding-left: 250px;
    max-width: 100%;
    flex: 0 0 100%;
  }
  .main-content:first-child {
    padding-left: 0;
  }

  .main-menu {
    display: block;
    z-index: 100;
    max-width: initial;
  }

  .secondary-menu {
    font-size: 1rem;
  }

  .menu-toggle {
    display: none;
  }
}
