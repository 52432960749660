.icon {
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
  vertical-align: middle;

  svg {
    vertical-align: top;
  }

  &.green {
   svg {
     .cls-1 {
       fill: $color4;
     }

     .cls-2 {
       fill: $white;
     }
   }
  }
}

.input-append {
  padding-right: 70px;
}

.input-prepend {
  padding-left: 50px;
}

.icon-reverse {
  transform: rotate(180deg);
}

.icon-7 {
  height: 7px;

  svg {
    height: 7px;
  }
}

.icon-10 {
  height: 10px;

  svg {
    height: 10px;
  }
}

.icon-15 {
  height: 15px;

  svg {
    height: 15px;
  }
}

.icon-20 {
  height: 20px;

  svg {
    height: 20px;
  }
}

.icon-25 {
  height: 25px;

  svg {
    height: 25px;
  }
}

.icon-30 {
  height: 30px;

  svg {
    height: 30px;
  }
}

.icon-35 {
  height: 35px;

  svg {
    height: 35px;
  }
}

.icon-40 {
  height: 30px;

  svg {
    height: 30px;
  }
}

.icon-50 {
  height: 30px;

  svg {
    height: 30px;
  }
}

.icon-60 {
  height: 35px;

  svg {
    height: 35px;
  }
}

.icon-100 {
  height: 60px;

  svg {
    height: 60px;
  }
}

.icon-150 {
  height: 80px;

  svg {
    height: 80px;
  }
}

.bg-background {
  background: var(--bg-background);
}

.icon-search {
  background: var(--search-check);
  width: 20px;
}

.icon-check {
  .cls-1 {
    fill: $color7;
  }
}

@media screen and (min-width: 980px) {
  .bg-background {
    padding: 20px;
  }
}

@media screen and (min-width: 980px) {
  .icon-40 {
    height: 40px;

    svg {
      height: 40px;
    }
  }

  .icon-50 {
    height: 50px;

    svg {
      height: 50px;
    }
  }

  .icon-60 {
    height: 60px;

    svg {
      height: 60px;
    }
  }

  .icon-100 {
    height: 100px;

    svg {
      height: 100px;
    }
  }

  .icon-150 {
    height: 150px;

    svg {
      height: 150px;
    }
  }
}
