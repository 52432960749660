.site-branding {
  background-color: $color1;
  color: $white;
}

.user-menu {
  position: relative;

  &:hover {
    .dropdown {
      display: block;
    }
  }

  .dropdown {
    background-color: $shadow;
    border: 1px solid grey;
    display: none;
    left: 0;
    padding: 0;
    position: absolute;
    top: 20px;
  }
}

.dropdown-cart-button {
  background-color: transparent;
  border:0;
}

.dropdown-cart {
  max-width: 100%;
  min-width: 300px;
  margin:0;

  .dropdown-item {
    color: $white;

    &:hover {
      background-color: transparent;
    }
  }
}

@media screen and (min-width: 520px) {
  .dropdown-cart {
    min-width: 450px;
  }
}

@media screen and (min-width: 768px) {
  .dropdown-cart {
    min-width: 500px;
    margin: 0
  }
}
