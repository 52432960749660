.btn-link {
  color: $color3;

  &:hover {
    cursor: pointer;
  }
}

.secondary-menu {
  .btn {
    color: $white;
  }
}

.h-select {
  min-height: 36px;
}

.btn-large {
  padding: .5em 3em;
}

.btn-bordered {
  border: 1px solid $text-color;
}

.btn-blue {
  background-color: $color1;
  border: 1px solid $color1;
  color: $white;

  &:hover {
    background-color: $white;
    color: $color1;
  }
}

.btn-color2 {
  background-color: $color2;
  border: 1px solid $color2;
  color: $white;

  &:hover {
    background-color: $white;
    color: $color2;
  }
}

.btn-green {
  background-color: $color4;
  border: 1px solid $color4;
  color: $white;

  &:hover {
    background-color: $white;
    color: $color4;

    .cls-1 {
      fill: $color4;
    }
  }

  .cls-1 {
    fill: $white;
  }
}

.btn-active {
  &.btn-green {
    background-color: $white;
    border-color: $text-color;
    color: $text-color;
  }

  .cls-1 {
    fill: $text-color;
  }
}

.link-shadowed {
  color: $shadow;
  font-size: smaller;
}

.btn-sm {
  padding: .2em 1em;
}

.icon-button {
  @include border-radius(40px);
  align-items: center;
  border: 1px solid $color1;
  display: flex;
  font-size: 20px;
  line-height: 30px;
  padding-right: 20px;
  transition: all 0.3s ease-in;

  &.button-active,
  &:hover {
    @include gradient2(to right, $color2, $color2 90%, black);
    color: $white;
    cursor: pointer;
  }

  img {
    left: -2px;
    position: relative;
  }

  .icon {
    margin-right: 5px;
  }
}



@media screen and (min-width: 980px) {
  .icon-button {
    font-size: 30px;
    line-height: 40px;
    padding-right: 35px;

    .icon {
      margin-right: 15px;
    }
  }

}
