@mixin border-radius($px) {
  -webkit-border-radius: $px;
  -moz-border-radius: $px;
  border-radius: $px;
}

@mixin transition($element, $time, $function) {
  -webkit-transition: $element $time $function;
  -moz-transition: $element $time $function;
  transition: $element $time $function;
}

@mixin box-shadow($top, $left, $blur, $spread, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $spread $color;
    -moz-box-shadow:inset $top $left $blur $spread $color;
    box-shadow:inset $top $left $blur $spread $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $spread $color;
    -moz-box-shadow: $top $left $blur $spread $color;
    box-shadow: $top $left $blur $spread $color;
  }
}

@mixin rounded($radius: 0.5em) {
  -webkit-border-radius: $radius !important;
  -moz-border-radius: $radius !important;
  border-radius: $radius !important;
}

@mixin gradient($angle, $from, $to) {
  background: linear-gradient($angle,  $from, $to);
}

@mixin gradient2($angle, $from, $to, $to2) {
  background: linear-gradient($angle,  $from, $to, $to2);
}

@mixin on-circle($item-count, $circle-size, $item-size) {
  position: relative;
  width:  $circle-size;
  height: $circle-size;

  > * {
    display: block;
    position: absolute;
    top:  50%;
    left: 50%;
    margin: -($item-size / 2);
    width:  $item-size;
    height: $item-size;

    $angle: (360 / $item-count);
    $rot: 0;

    @for $i from 1 through $item-count {
      &:nth-of-type(#{$i}) {
        transform: rotate($rot * 1deg) translate($circle-size / 2) rotate($rot * -1deg);
      }

      $rot: $rot + $angle;
    }
  }
}

