.ngx-slider-inner-tooltip {
  display: none;
}

.ngx-slider {
  margin: 15px 0 35px !important;

  .ngx-slider-tick-legend {
    font-size: 13px;
    line-height: 20px;
    max-height: 40px;
    max-width: 80px;
    overflow: hidden;
  }
}

.custom-logo {
  height: 117px;
  width: auto;
}

app-cart {
  svg-icon {
    .cls-1 {
      stroke: none;
    }
    circle.cls-2 {
      fill: none;
    }
  }
}

.text-color-1 {
  color: $color1;
}

.text-color-2 {
  color: $color2;
}

.bdl-blue {
  border-left: 15px solid $color3;
}

.box {
  background-color: $white;
  border: 2px solid $color-grey;
  padding: 1em;
}

.font-size-0 {
  font-size: 0;
}

.panel-open {
  > .product-list {
    > .panel-heading .btn {
      &:before {
        content: "-";
      }
    }
  }
}

.border.boder-dashed {
  border-style: dashed;
  border-color: $color4;
}

img.rounded-light {
  max-height: 250px;
}

.hidden-reply {
  .ticket {
    display: none;
  }

  .first {
    display: block;
    .bd-bottom {
      border-bottom: 0;
    }
  }

  .last {
    display: block;
  }
}

.ticket {
  -webkit-animation-duration: 0.5s;
  -webkit-animation-name: fadeInFromNone;
  -webkit-animation-fill-mode: forwards;

  .badge {
    font-size: 20px;
    left: 20px;
    line-height: 30px;
    top: -15px;

    &:hover {
      cursor: pointer;
    }
  }
}

.card {
  @include border-radius(1px);
}

.product-list {
  margin-bottom: 10px;

  .panel-title {
    line-height: 30px;

    .btn {
      padding: 3px 10px;

      &:before {
        content: "+";
        display: inline-block;
        font-size: 25px;
        font-weight: bold;
        height: 25px;
        width: 25px;
      }

      .btn {
        text-transform: initial;

        &:before {
          display: none;
        }
      }
    }
  }

  .panel-body {
    padding: 0;
  }

  .panel-heading {
    line-height: 25px;
    padding: 0;

    .btn {
      line-height: 25px;
      text-transform: uppercase;
    }
  }
}

.credit-card {
  .title {
    padding-right: 40px;
  }

  .delete {
    color: $color-grey;
    right: 0;
    top: 0;
  }
}

.domain {
  > div {
    height: 35px;
  }
}

.product {
  &:hover {
    background-color: $color9;
  }
  .item-availability {
    text-align: center;
    width: 80px;
  }
  &.bd-bottom {
    border-color: $color2;
  }
}

.product-name {
  line-height: 28px;
  margin-bottom: 10px;
  width: 100%;
}

.product-description {
  @extend .product-name;
}

.page-title {
  background-color: $color2;
  color: $white;
  font-size: 16px;
  padding: 5px 20px;
}

.btn-rounded {
  select {
    background: none;
    border: 0;
  }
}

.number-spinner {
  .lower {
    border-bottom: 1px solid $text-color;
    border-bottom-left-radius: 20px;
    border-left: 1px solid $text-color;
    border-top: 1px solid $text-color;
    border-top-left-radius: 20px;
    padding: 0.2em 1em;
  }

  .value {
    border: 1px solid $text-color;
    padding: 0.2em 1em;
  }

  .up {
    border-bottom: 1px solid $text-color;
    border-bottom-right-radius: 20px;
    border-right: 1px solid $text-color;
    border-top: 1px solid $text-color;
    border-top-right-radius: 20px;
    padding: 0.2em 1em;
  }
}

.status-icon {
  border-radius: 50%;
  display: inline-block;
  height: 15px;
  width: 15px;
}

.status-grey {
  background-color: $shadow;
}

.status-green {
  background-color: $color4;
  color: $white;
}

.status-darkgreen {
  background-color: $color7;
  color: $white;
}

.status-blue {
  background-color: $color2;
  color: $white;
}

.status-black {
  background-color: $black;
  color: $white;
}

.status-orange {
  background-color: $color5;
  color: $white;
}

.status-red {
  background-color: $red;
  color: $white;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.expandable-div {
  height: 60px;
  overflow: hidden;

  &.active {
    height: auto;
  }
}

.expandable {
  border-bottom: 1px solid $text-color;
  position: relative;

  &.expanded {
    svg {
      -webkit-animation-duration: 0.5s;
      -webkit-animation-name: rotate180;
      -webkit-animation-fill-mode: both;
    }
  }

  .arrow {
    @include border-radius(50%);
    bottom: -20px;
    padding: 10px;
    position: absolute;
    left: calc(50% - 10px);

    &:hover {
      background-color: $color2;

      .cls-1 {
        fill: $white;
      }
    }
  }
}

.file-upload {
  .drop-zone {
    text-align: right;
  }

  .upload-icon {
    left: 15px;
    top: 15px;
  }
}

ngx-slider.ngx-slider .ngx-slider-pointer {
  height: 24px;
  top: -11px;
  width: 24px;

  &:after {
    left: 8px;
    top: 8px;
  }
}

.euro-banner {
  background: $color1;
  bottom: 0;
  color: $nav-a-bg;
  font-size: 12px;
  left: 0;
  position: fixed;
  right: 0;
}

.cart-margin-euro {
  padding-bottom: 100px !important;
}

.padding-select {
  font-size: 14px;
  padding: 3px 30px 3px 10px;
}
@media screen and (min-width: 768px) {
  .euro-banner {
    left: 250px;
  }
  .cart-margin-euro {
    padding-bottom: 70px !important;
  }
  .site-content.bg-grey {
    min-height: 100%;
    padding-bottom: 50px;
  }
  .box {
    padding: 1em 3em;
  }

  .product {
    > div {
      height: auto;
    }
  }

  .product-name {
    margin-bottom: 0;
    width: 20%;
  }

  .product-description {
    width: 40%;
  }
  .cart-header-title {
    font-size: 16px;
    color: black;
  }
}

@media screen and (min-width: 1280px) {
  .product-name {
    width: 10%;
  }

  .product-description {
    width: 50%;
  }
}

@media screen and (min-width: 1px) {
  body > app-root > div.container-fluid > .row:not(:has(.main-menu)) {
    display: block;
  }
  body > app-root > div.container-fluid > .row::has(.main-menu) {
    display: flex;
  }

  body:not(:has(.main-menu)) .euro-banner {
    left: 0px;
  }
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}
