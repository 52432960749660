.table {
  td {
    vertical-align: middle;
    padding: 5px 10px;
  }

  th {
    vertical-align: middle;
  }
}

.thead-dark {
  tr {
    background: $color2;
    color: $white;
    font-weight: bold;
  }
}
