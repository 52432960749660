.cover-image {
  overflow: hidden;
  position: relative;

  .divider-before {
    border-color: transparent $color2;
    border-width: 300px 0 0 125px;
    left: 0;
  }

  .divider-after {
    border-width: 300px 0 0 125px;
    border-color: $color2 transparent transparent;
    right: -50px;
  }
}

%divider {
  border-style: solid;
  height: 100%;
  position: absolute;
  top: 0;
  width: 0;
  z-index: 2;

  &:before {
    background: linear-gradient(to top, $color-gradient-blue, $color-gradient-blue2 55%, $color-gradient-blue2);
    bottom: 15px;
    content: '';
    height: 275px;
    left: -67px;
    position: absolute;
    transform: rotate(-22.6deg);
    width: 7px;
  }
}


.divider-before {
  @extend %divider;
}

.divider-after {
  @extend %divider;
}

@media screen and (min-width: 767px) {
  .cover-image {
    .divider-before {
      left: 0;
    }

    .divider-after {
      right: 0;
    }
  }
}
