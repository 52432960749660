.login-wrapper {
  align-items: center;
  min-height: 90%;
}

form {
  flex-direction: column;

  hr {
    background-color: $color-grey;
    border: 0;
    height: 5px;
  }
}

.oko {
  &.active {
    .cls-1 {
      fill: $color4;
    }
  }
}

.form-group {
  margin-bottom: 10px;
}

select {
  @include border-radius(20px);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: $white;
  background-image: var(--select-bg-path);
  background-position: 95% center;
  background-repeat: no-repeat;
  background-size: 13px;
  border: 1px solid $text-color;
  box-shadow: none;
  font-size: 14px;
  padding: 3px 30px 3px 10px;
  width: 100%;
}

ng-select.ng-invalid.ng-touched .ng-select-container {
  border-color: #dc3545;
}

ngx-intl-tel-input > div {
  width: 100%;
}

input[type="checkbox"] {
  -webkit-appearance: checkbox;
}

.check-container {
  cursor: pointer;
  display: inline-block;
  height: 24px;
  margin-bottom: 0;
  position: relative;
  user-select: none;
  vertical-align: middle;
  width: 40px;

  &:hover {
    input ~ .checkmark {
      background-color: $color8;
    }
  }

  input {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;

    &:checked ~ .checkmark {
      background-color: $white;
      border-color: $text-color;

      &:after {
        background-color: $color7;
        left: auto;
        right: 1px;
      }
    }
  }

  .checkmark {
    @include border-radius(20px);
    border: 1px solid $text-color;
    height: 24px;
    left: 0;
    position: absolute;
    top: 0;
    width: 40px;

    &:after {
      @include border-radius(20px);
      background-color: $text-color;
      content: "";
      left: 1px;
      height: 20px;
      position: absolute;
      top: 1px;
      width: 20px;
    }
  }
}

@media screen and (min-width: 768px) {
  form {
    flex-direction: row;
  }
}
